html {
  height: 100%;
}
body {
  background-image: url('./bg2.jpg');
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: white;
  font-family: sans-serif;
  text-align: left;
}

@media only screen and (min-width: 600px) {
  body {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.Inputs {
  display: flex;
  flex-direction: column;
}
label {
  display: flex;
  flex-direction: column;
  align-items: flex;
  margin: 0.5em 0em;
  text-transform: uppercase;
  color: #fff;
}

a {
  color: #ffcea2;
}

h1,
h2,
h3 {
  color: #fff;
  font-weight: lighter;
}
h1 {
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid white;
}

main > section {
  color: white;
  text-transform: uppercase;
}

input {
  font-size: 18px;
  margin-top: 2px;
  display: block;
  text-align: left;
  padding: 10px;
  background: #000;
  border: none;
  border-bottom: solid 2px #222831;
  opacity: 0.6;
  border-radius: 7px;
  color: #fff;
}

table {
  width: 100%;
}
